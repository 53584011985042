
<div class="container">
   
    <div class="header-container">
        <div class="header-image-container"> <img class="shiksha-logo"
                src="../../../../assets/Images/shikshalokam-logo.png" alt="">
        </div>
        <div class="log-out" *ngIf="isUserLogin" (click)="onLogout()">
            <button mat-raised-button>Logout</button>
        </div>

    </div>
    <div *ngIf="loader" class="spinner">
        <mat-spinner  [diameter]="70"></mat-spinner>
    </div>
    <div class="template-selection-container" *ngIf="!loader">

        <mat-card class="tempplate-download-card">
            <div class="tempplate-download">
                <p>Template Download Section</p>
            </div>

            <mat-card-content class="tempplate-download-section">

                <div class="card-conatiner-download">

                    <div>
                        <p>Please select template to download</p>
                        <div class="template-button">
                            <button mat-raised-button class="select-button">
                                <mat-select placeholder="Select Template">
                                    <mat-option *ngFor="let downloadTemplate of downloadTemplates"
                                        [value]="downloadTemplate" class="select-opt"
                                        (click)="onCickSelectedTemplate(downloadTemplate)">
                                        {{ downloadTemplate.name | titlecase }}
                                    </mat-option>
                                </mat-select>
                            </button>
                        </div>

                    </div>



                    <div class="template-button">&nbsp;&nbsp;&nbsp;
                        <button (click)="templateDownload()" mat-raised-button class="download-button"> Download
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="tempplate-upload-card">
            <div class="tempplate-upload">
                <p>Template Upload Section</p>
            </div>

            <mat-card-content class="tempplate-upload-section">

                <div class="card-conatiner-upload">
                    <div>
                        <p>Please select file type to upload</p>
                        <div class="template-button">
                            <button mat-raised-button class="select-button">
                                <mat-select placeholder="Select file type">
                                    <mat-option *ngFor="let uploadTempplate of uploadTemplates"
                                        [value]="uploadTempplate" class="select-opt"
                                        (click)="fileUpload(fileInput,uploadTempplate)" (change)="onChange($event)">
                                        <input type="file" accept=".xlsx" style="display: none;" #fileInput
                                            (change)="getFileDetails($event)" />
                                        <span> {{uploadTempplate | titlecase}} </span>
                                    </mat-option>
                                </mat-select>
                            </button>
                        </div>
                    </div>

                    <div class="template-fileName"> {{fileName}}</div>
                    <div class="template-upload-button-container">&nbsp;&nbsp;&nbsp;
                        <button (click)="templateUpload()" mat-raised-button class="upload-button"> Upload </button>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>