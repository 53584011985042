<div>
    <div> <img class="shiksha-logo" src="../../../../assets/Images/shikshalokam-logo.png" alt=""></div>

    <div class="download-horizontal">
        <hr />
    </div>
    <div class="tempplate-download">
        <p class="template-sections">Validation Result</p>
    </div>

    <div class="report-details">
        <button mat-raised-button class="report-request">Number of request received -{{report_request}}</button>
        <button mat-raised-button class="report-success">Number of request Successful -{{report_success}} </button>
        <button mat-raised-button class="report-failed"> Number of request failed -{{report_failed}} </button>
    </div>
</div>