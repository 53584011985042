<div>
    <div class="header-container">
        <div class="header-image-container"> <img class="shiksha-logo"
                src="../../../../assets/Images/shikshalokam-logo.png" alt="">
        </div>
        <div class="log-out" *ngIf="isUserLogin" (click)="onLogout()">
            <button mat-raised-button>Logout</button>
        </div>

    </div>
    <section class="back-button-container">
        <mat-icon class="back-icon" (click)="goBack()">arrow_back</mat-icon>
        <p>Back</p>
    </section>
    <div class="validation-result">
        <p class="validation-sections">Validation Result</p>
    </div>
    <div>
        <!-- <div class="templates-text">
            <p> Resource</p>
        </div> -->

        <div class="flex-container">
            <div class="download_div">
                <div class="export_div">

                    <button (click)="export()" mat-raised-button class="export-button"> <mat-icon>download</mat-icon> Export excel </button>

                </div>
                <div class="export_div">

                    <button mat-raised-button class="export-button" (click)="errorExcelDownload()"> <mat-icon>download</mat-icon> Export errors </button>

                </div>
                <!-- <div>
                    <button mat-raised-button class="report-button"> Reports </button>
                </div> -->

            </div>

            <!-- <div class="export_report_div">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            </div>
            -->
            <div class="table_container">
                <div class="arrayOfSheet_div">
                    <div *ngFor="let s of sheetarr">
                        <button class="arrayOfSheet"   [ngClass]="{'active':isSlectedSheet(s)}" *ngIf="s !== 'Instructions'"
                            (click)="onClickSheetName(s)">{{s}}</button>
                    </div>
                </div>



                <table id="table" *ngIf="data" mat-table [dataSource]="data" class="mat-elevation-z8">
                    <ng-container *ngFor="let columnName of columnNames; let j=index" [matColumnDef]="columnName">
                        <div>
                            <th mat-header-cell *matHeaderCellDef >{{ capitalize(columnName) }}</th>

                            <td mat-cell *matCellDef="let row;let i=index;"
                                [ngClass]="{'selected':isContainsError(columnName,row[columnName],row,i),'selectedFirstRow':firstRow(i)}">{{ row[columnName] }}
                                <ng-template #tooltipTemplate>
                                    <div class="close-icon">
                                        <mat-icon class="mat-icon-content-copy" (click)="copyToClipBoard(getErrorsList(columnName,i), getBasicErrors(columnName,i))">content_copy</mat-icon>
                                        <!-- <mat-icon [appCustomTooltip]="tooltipTemplate" class="mat-icon-close">close</mat-icon> -->
                                    </div>
                                    <div>
                                        <div *ngFor="let error of getErrorsList(columnName,i)">
                                            <p class="m-4">Error : {{error.error}}</p>
                                            <p class="m-4">Suggestions : {{error.suggestion}}</p>
                                        </div>
                                        <div *ngFor="let error of getBasicErrors(columnName,i)">
                                            <p class="m-4">Error : {{error.error}}</p>
                                            <p class="m-4">Suggestions : {{error.suggestion}}</p>
                                        </div>
                                    </div>
                                </ng-template>
                                <mat-icon [appCustomTooltip]="tooltipTemplate"  *ngIf="isContainsError(columnName,row[columnName],row,i)" class="mat-icon-info">info</mat-icon>
                            </td>

                        </div>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnNames" class="highlight"></tr>

                </table>
            </div>

        </div>
    </div>
    <div>

    </div>
</div>
